<template>
    <section>
        <div class="card">
            <div class="card-content">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <span class="mr-1">Por página:</span>
                            <b-select
                                v-model="pagination.per_page"
                                @input="fetchEmpleados"
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </b-select>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <b-tag type="is-light"
                                ><b class="mr-1">{{ totalEmpleados }}</b
                                >registros</b-tag
                            >
                        </div>
                        <div class="level-item">
                            <b-button
                                icon-left="download"
                                :disabled="empleados.length === 0"
                                @click="exportModal = true"
                            >
                                Exportar
                            </b-button>
                        </div>
                    </div>
                </div>
                <b-table
                    :data="empleados"
                    :loading="isLoading"
                    :paginated="true"
                    :per-page="pagination.per_page"
                    :current-page="pagination.page"
                    :total="totalEmpleados"
                    @page-change="handleChangePage"
                    hoverable
                    backend-pagination
                >
                    <template #empty>
                        <div class="has-text-centered">No hay registros</div>
                    </template>

                    <b-table-column field="usuario" v-slot="props">
                        <div style="width: 40px; height: 40px; margin: auto">
                            <img
                                v-if="props.ruta_imagen"
                                :src="props.ruta_imagen"
                                :alt="props.username"
                                class="avatar-image"
                            />
                            <no-user-image
                                v-else
                                size="small"
                                :trabajador="props.row"
                            />
                        </div>
                    </b-table-column>

                    <b-table-column
                        field="nombre"
                        label="Nombre Completo"
                        v-slot="props"
                    >
                        {{ props.row.apellido_paterno }}
                        {{ props.row.apellido_materno }}
                        {{ props.row.nombre }}
                    </b-table-column>

                    <b-table-column field="id" label="DNI" v-slot="props">
                        {{ props.row.dni }}
                    </b-table-column>

                    <b-table-column field="pais" label="Pais" v-slot="props">
                        <div class="is-flex is-align-content-center">
                            <b-tooltip :label="props.row.pais" type="is-black">
                                <img
                                    :src="props.row.pais_ruta_imagen"
                                    class="image is-16x16"
                                    alt="country-flag"
                                />
                            </b-tooltip>
                            <span class="ml-1">{{ props.row.pais }}</span>
                        </div>
                    </b-table-column>

                    <b-table-column field="email" label="Email" v-slot="props">
                        {{ props.row.email ? props.row.email : '-' }}
                    </b-table-column>

                    <b-table-column
                        v-for="dato in datosLaborales"
                        :key="dato.id"
                        :label="dato.descripcion"
                        v-slot="props"
                    >
                        {{ props.row[dato.slug] }}
                    </b-table-column>

                    <b-table-column label="Estado" v-slot="props">
                        <span :class="['tag', props.row.activo ? 'is-success' : 'is-danger']">
                            {{ props.row.activo ? 'Activo' : 'No Activo' }}
                        </span>
                    </b-table-column>

                    <b-table-column label="Acciones" v-slot="props">
                        <b-tooltip label="Ver Detalle" type="is-dark">
                            <a @click="toEmpleadoDetalle(props.row)">
                                <b-icon icon="account-details"></b-icon>
                            </a>
                        </b-tooltip>
                    </b-table-column>
                </b-table>
            </div>
        </div>

        <b-modal v-model="exportModal" :width="640">
            <div class="box is-relative">
                <b-loading v-model="exportLoading" :is-full-page="false"></b-loading>
                <ul>
                    <li v-for="exportOption of exportOptions" :key="exportOption.name">
                        <a class="box m-3" @click.prevent="exportData(exportOption.value)">
                            <b class="has-text-primary-dark is-flex is-align-items-center">
                                <b-icon icon="download" /><span>{{ exportOption.name }}</span>
                            </b>
                        </a>
                    </li>
                </ul>
            </div>
        </b-modal>
    </section>
</template>

<script>
import NoUserImage from '@/components/shared/NoUserImage.vue';
import Message from '@/utils/Message';

export default {
    props: {
        datosLaborales: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        camposDatosLaboralesIds: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default: () => ({
                page: 1,
                perPage: 10,
            }),
        }
    },
    components: {
        NoUserImage,
    },
    data() {
        return {
            exportModal: false,
            exportLoading: false,
            exportOptions: [
                {
                    value: 'csv',
                    name: 'CSV'
                },
                {
                    value: 'ods',
                    name: 'ODS'
                },
                {
                    value: 'xlsx',
                    name: 'XLSX'
                },
            ],
            perPage: 10,
            trabajadorHoveredId: '',
        };
    },
    computed: {
        empleados() {
            return this.$store.state.empresas.empleados;
        },
        isLoading() {
            return this.$store.state.empresas.isLoading;
        },
        totalEmpleados() {
            return this.$store.state.empresas.empleadosPagination.total;
        },
        totalPages() {
            return this.$store.state.empresas.empleadosPagination.total_pages;
        },
    },
    methods: {
        handleChangePage(page) {
            this.pagination.page = page;
            this.fetchEmpleados();
        },
        toEmpleadoDetalle(row) {
            this.$router.push({
                name: 'admin-trabajadores-detail',
                params: { persona_id: row.dni },
            });
        },
        async exportData(exportOption) {
            try {
                this.exportLoading = true;
                await this.$store.dispatch('trabajadores/export', { format: exportOption });
                Message.showSuccessMessage(this, 'Documento obtenido');
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.exportLoading = false;
            }
        },
        async fetchEmpleados() {
            try {
                await this.$store.dispatch('empresas/getEmpleados', {
                    ...this.filters,
                    ...this.pagination,
                    campos_datos_laborales_ids: this.camposDatosLaboralesIds,
                });
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        },
    },
};
</script>
