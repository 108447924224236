<template>
    <div class="container">
        <div
            class="
                content
                is-flex is-align-items-center is-justify-content-space-between
            "
        >
            <h2 class="has-text-primary-dark">Trabajadores</h2>
        </div>
        <form @submit.prevent="fetchEmpleados()">
            <b-field
                class="search--input"
                label="Buscar"
                label-position="on-border"
                grouped
            >
                <b-input
                    v-model="filters.query"
                    placeholder="Buscar por DNI, Nombre, Apellido o Email"
                    type="search"
                    expanded
                ></b-input>
            </b-field>
            <div class="search--options">
                <a @click.prevent="verMasOpciones = !verMasOpciones">
                    <b-icon
                        v-if="!verMasOpciones"
                        icon="menu-down"
                        size="is-small"
                    />
                    <b-icon v-else icon="menu-up" size="is-small" />
                    {{ !verMasOpciones ? 'Más Opciones' : 'Ocultar' }}
                </a>
            </div>
            <transition name="fade">
                <div v-show="verMasOpciones">
                    <div class="box">
                        <div class="columns">
                            <div class="column">
                                <p class="has-text-weight-bold mb-2">
                                    Campo Laborales
                                </p>
                                <b-checkbox
                                    v-for="campo in camposDatosLaborales"
                                    :key="campo.id"
                                    v-model="camposDatosLaboralesIds"
                                    :native-value="campo.id"
                                >
                                    {{ campo.descripcion }}
                                </b-checkbox>
                            </div>
                            <div class="column">
                                <p class="has-text-weight-bold mb-2">País</p>
                                <b-select
                                    placeholder="Selecciona un país"
                                    v-model="filters.pais_id"
                                    expanded
                                >
                                    <option
                                        v-for="pais in paises"
                                        :value="pais.id"
                                        :key="pais.id"
                                    >
                                        {{ pais.descripcion }}
                                    </option>
                                </b-select>
                            </div>
                            <div class="column">
                                <p class="has-text-weight-bold mb-2">
                                    Tipo de usuario
                                </p>
                                <b-select
                                    placeholder="Selecciona un país"
                                    v-model="filters.tipo_perfil"
                                    expanded
                                >
                                    <option
                                        v-for="tipo_perfil in [
                                            'Empleado',
                                            'Recursos Humanos',
                                        ]"
                                        :value="tipo_perfil"
                                        :key="tipo_perfil"
                                    >
                                        {{ tipo_perfil }}
                                    </option>
                                </b-select>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <b-button
                native-type="submit"
                class="button is-primary mt-2 search--submit"
                :loading="isLoading || isLoadingCamposLaborales"
            >
                Buscar
            </b-button>
        </form>
        <br />
        <tabla-trabajadores
            :datos-laborales="selectedCamposLaborales"
            :pagination="pagination"
            :filters="filters"
            :camposDatosLaboralesIds="camposDatosLaboralesIds"
        />
    </div>
</template>

<script>
import TablaTrabajadores from '@/components/Trabajadores/TablaTrabajadores.vue';
import Message from '@/utils/Message';

export default {
    name: 'Trabajadores',
    components: {
        TablaTrabajadores,
    },
    data() {
        return {
            verMasOpciones: false,
            camposDatosLaboralesIds: [],
            filters: {
                query: '',
                pais_id: '',
                tipo_perfil: 'Empleado',
            },
            pagination: {
                page: 1,
                per_page: 10,
            },
        };
    },
    async mounted() {
        await this.fetchCampoDatosLaborales();
        await this.fetchPaises();
        this.camposDatosLaboralesIds = this.camposDatosLaborales.map(
            campo => campo.id
        );

        await this.fetchEmpleados();
    },
    computed: {
        isLoading() {
            return this.$store.state.empresas.isLoading;
        },
        isLoadingCamposLaborales() {
            return this.$store.state.camposDatosLaborales.isLoading;
        },
        camposDatosLaborales() {
            return this.$store.state.camposDatosLaborales.data;
        },
        paises() {
            return [
                { id: '', descripcion: 'TODOS' },
                ...this.$store.state.paises.data,
            ];
        },
        selectedCamposLaborales() {
            return this.camposDatosLaborales.filter(campo =>
                this.camposDatosLaboralesIds.includes(campo.id)
            );
        },
    },
    methods: {
        async fetchCampoDatosLaborales() {
            await this.$store.dispatch('camposDatosLaborales/get');
        },
        async fetchPaises() {
            await this.$store.dispatch('paises/get');
        },
        async fetchEmpleados() {
            try {
                await this.$store.dispatch('empresas/getEmpleados', {
                    ...this.filters,
                    ...this.pagination,
                    campos_datos_laborales_ids: this.camposDatosLaboralesIds,
                });
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.search--input {
    width: 100%;
}

.search--options {
    width: 30%;
}

@media screen and (max-width: 768px) {
    .search--input,
    .search--options,
    .search--submit {
        width: 100%;
    }
}
</style>
